<template>
  <div>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="show"
      max-width="500px"
      persistent
    >
      <div class="text-center" v-if="loading" style="min-height: 500px">
        <v-progress-circular
          :size="100"
          :width="10"
          style="
            text-align: center;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 200px;
          "
          p
          color="#7253CF"
          indeterminate
        ></v-progress-circular>
      </div>

      <v-card background-color="#f2f2f2" v-if="!loading">
        <v-card-title
          class="py-0 pt-5"
          style="background-color: #7253cf; color: white"
        >
          <div class="d-flex px-1">
            <img src="@/assets/teamModalLogo.svg" height="85" width="100" />
          </div>
          <div class="ml-1 dialogTitle-text">
            {{ dialogTitle }}
          </div>
          <v-spacer></v-spacer>
          <v-icon
            text
            size="27"
            color="white"
            style="
              cursor: pointer;
              position: relative;
              right: -15px;
              bottom: 40px;
            "
            @click="
              toggleAddTeamModal({ show: false });
              reloadPopup();
            "
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-form lazy-validation ref="teamForm" class="pt-10">
            <v-row no-gutters justify="center">
              <v-col cols="10">
                <v-text-field
                  outlined
                  dense
                  v-model="team_name"
                  label="Search by Team Name/Add New Team Name"
                  append-icon="mdi-magnify"
                  type="text"
                  color="#38227A"
                  height="43px"
                >
                </v-text-field>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="2" class="pl-2">
                <v-btn
                  class="rounded-lg dialogAction-btnText py-5 px-4"
                  dark
                  color="#38227A"
                  @click="getteamPlayer()"
                >
                  <span>Enter</span>
                </v-btn>
              </v-col>
            </v-row>
            <h3 class="ml-3">Players</h3>
            <v-data-table :items="teamNameDataTable.items">
              <template v-slot:item="props">
                <tr>
                  <td>
                    <v-checkbox
                      v-model="props.item.is_in_team"
                      color="#7253CF"
                      class="formFields"
                      :label="props.item.name"
                      @change="checkbox(props.item.id, props.item.is_in_team)"
                    ></v-checkbox>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-row overflow-x> </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0 pb-7 px-10">
          <v-spacer></v-spacer>
          <v-btn
            v-if="type == 'add'"
            :loading="formLoading"
            class="rounded-lg dialogAction-btnText py-5 px-4"
            dark
            color="#38227A"
            @click="submitForm"
          >
            <span>Add Team</span>
          </v-btn>
          <v-btn
            :loading="formLoading"
            v-if="type == 'edit'"
            class="rounded-lg dialogAction-btnText py-5 px-4"
            dark
            color="#38227A"
            @click="submitForm"
          >
            <span>Save</span></v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import RULES from "@/common/fieldRules";
import {
  API_NEW_TEAM_LIST_POST,
  API_NEW_TEAM_LIST_PATCH,
  API_NEW_TEAM_LIST_DETAIL,
  API_EVENT_TEAM_PLAYER_LIST_GET,
} from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
export default {
  name: "AddTeamModal",
  data() {
    return {
      rules: RULES,
      loading: false,
      formLoading: false,
      name: "",
      email: "",
      role: null,
      team_name: "",
      is_in_team: false,
      playerList: [],
      players_user_id_lists: [],
      temp_players_user_id_lists: [],
      playerId: [],
      teamDetail: {},
      teamNameDataTable: { items: [] },
    };
  },
  computed: {
    ...mapGetters({
      showGetter: "teamManagement/getShowTeam",
    }),
    layout() {
      return this.$route.meta.layout;
    },
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        return this.toggleAddTeamModal({ show: value });
      },
    },
    type() {
      return this.$store.state.teamManagement.addTeamModal.type;
    },
    toastMessage() {
      if (this.type === "add") {
        return "Team Added";
      } else {
        return "Team  Updated";
      }
    },
    dialogTitle() {
      switch (this.type) {
        case "add":
          return "Create New Team";
        case "edit":
          return "Edit Team";
        default:
          return "";
      }
    },
  },
  watch: {
    show(value) {
      if (value) {
        // Modal open callback
        this.openCallBackModal();
      } else {
        // Modal close callback
        this.closeCallBackModal();
      }
    },
  },
  methods: {
    ...mapActions({
      // sponsor Modal
      toggleAddTeamModal: "teamManagement/toggleTeamModal",
      showToast: "snackBar/showToast",
    }),
    openCallBackModal() {
      this.getteamPlayer();
      if (this.type === "edit") {
        this.getTeamDetail();
      }
    },
    closeCallBackModal() {
      this.loading = false;
      this.formLoading = false;
      this.$refs.teamForm.reset();
    },
    getTeamDetail() {
      const self = this;
      const successHandler = (res) => {
        this.teamDetail = res.data.team_detail;
        this.team_name = this.teamDetail.team_name;
        self.teamNameDataTable.items = this.teamDetail.player_list;
        this.players_user_id_lists = [];

        for (let i = 0; i < this.teamDetail.player_list.length; i++) {
          const player = this.teamDetail.player_list[i];
          if (player.is_in_team) {
            this.players_user_id_lists.push(player.id);
          }
        }
        self.loading = false;
      };
      const failureHandler = () => {
        self.loading = false;
      };
      let formData = {};
      formData["team_id"] =
        self.$store.state.teamManagement.addTeamModal.teamID;
      Axios.request_GET(
        API_NEW_TEAM_LIST_DETAIL,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    checkbox(id, is_in_team) {
      if (is_in_team) {
        this.players_user_id_lists.push(id);
      } else {
        const index = this.players_user_id_lists.indexOf(id);
        if (index !== -1) {
          this.players_user_id_lists.splice(index, 1);
        }
      }
    },
    getteamPlayer() {
      const successHandler = (res) => {
        this.playerList = res.data.player_list;
        this.teamNameDataTable.items = res.data.player_list;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      const finallyHandler = () => {};
      let formData = {};
      if (this.team_name) {
        formData["team_name"] = this.team_name;
      }
      Axios.request_GET(
        API_EVENT_TEAM_PLAYER_LIST_GET,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    submitForm() {
      const self = this;
      self.formLoading = true;
      const successHandler = () => {
        self.formLoading = false;
        self.toggleAddTeamModal({ show: false });
        //self.$emit("reload");
        location.reload();
        this.showToast({
          message: this.toastMessage,
          color: "s",
        });
      };
      const failureHandler = (res) => {
        self.formLoading = false;
        this.showToast({
          message: res,
          color: "e",
        });
      };
      let formData = {};
      formData["team_name"] = this.team_name;
      formData["team_type"] = "pqr";
      formData["active_team"] = true;
      formData["is_delete"] = true;
      formData["players"] = this.players_user_id_lists;
      if (this.type === "edit") {
        formData["id"] = this.$store.state.teamManagement.addTeamModal.teamID;
      }
      if (this.type === "add") {
        Axios.request_POST(
          API_NEW_TEAM_LIST_POST,
          formData,
          {},
          successHandler,
          failureHandler,
          false
        );
      } else {
        Axios.request_PATCH(
          API_NEW_TEAM_LIST_PATCH,
          formData,
          {},
          successHandler,
          failureHandler,
          false
        );
      }
    },
    reloadPopup() {
      location.reload();
    },
  },
};
</script>
<style scoped>
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  /* identical to box height */

  color: #757575;
}
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 315%;
  color: #ffffff;
}

.dialogAction-btnText >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 161.5%;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
}
</style>
